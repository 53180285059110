.page {
    background-image: linear-gradient(grey, black);
    height: 100vh;
}
.button {
    color:white;
    width: '100%';
}
.WordCount {
    position: absolute;
    right: 15px;
}
.reflectionTitle{
    padding-left: 10px;
}