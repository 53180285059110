.bodyData{
    background-image: none;
    background-color: rgb(239, 239, 239);
    
}

.navbar{
    background-color: #ffff;
    font-family: Arial, Helvetica, sans-serif;
}

h1 {
    text-align: center;
    padding-top:5%;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
}
p {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 2%;
    color: black;
}

h3{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 2%;
    color: black;
}

.square {
    display: flex;
    width: 600px;
    height: 600px;
    background-color: #b4ceb3;
    border-radius: 8%;
    padding-top: 5%;
    justify-content: center;
    margin: 0 auto;
  }

  .search{
    justify-content: center;
    margin: 0 auto;
    display: flex;
    padding-top: 15%;
    width:60%;

    position:relative;
    align-items: center;
  }

  .searchButton{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-top: 2%;
  }

  .navbar-brand{
    color:black;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .nav-link{
    color:black;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    
  }

  .login
  {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 12%;
  }

  .login-button{
    display: flex;
    justify-content: center;
    margin: auto;
  }
  
  .center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .datasets{
    width:60%;
    padding-top: 5%;
    justify-content: center;
    margin:auto;
  }

.listText
{
  white-space: pre-line;
}
.accordion-button{
  background-color:rgb(220, 220, 220);
  color:black;
  font-weight: bold;
}
.accordion-button:not(.collapsed) {
  background-color:rgb(220, 220, 220);
  color:black;
  font-weight: bold;
}

.accordion{
  --bs-accordion-bg:rgb(220, 220, 220);
}

/* .badge{
  margin-left:5px;
} */

.input-num{
  text-align: center;
  justify-content: center;
  margin:auto, 0;
  padding-top: 1%;
  font-weight: bold;
}

.result-text{
  text-align: center;
  padding-top:0%;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.align-right {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.align-right .buttonDelete {
  margin-left: 10px; /* Adjust spacing as needed */
  font-weight: bold;
}

.bg-primary {
  background-color: #C6CA53 !important;
  color:black;
}

.btn-delete {
  --bs-btn-color: black;
  --bs-btn-bg: #C6CA53;
  --bs-btn-border-color: #C6CA53;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #C9DCB3;
  --bs-btn-hover-border-color: #C9DCB3;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #C6CA53;
  --bs-btn-active-border-color: #C6CA53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #C6CA53;
  --bs-btn-disabled-border-color: #C6CA53;
}

.relative-container { 
  position: relative;
  width: 100%;
}

.nav-bar-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: auto;  
}

.nav-bar-right
{
  position: absolute;
  right: 10px; /*Align to the right*/
  width: auto; 
}

.logo-text{
  margin-left: 8px;
}

.item-title-and-cost
{
  font-weight: bold;
}


.landing-page-text{
  justify-content: center;
  margin: auto;
  text-align: center;

  font-weight: bold;
  font-size:70px;
  max-width: 15ch;
  /* display: inline-block;  */
  padding-top: 4%;
  line-height: 1.5ch;
}

.subtext{
  font-size:25px;
  font-weight: 500;
  max-width: 25ch;
  padding-bottom: 20px;
}


@media (max-width: 600px) {
  .landing-page-text {
      font-size: 10vw; /* Adjust font size for smaller screens */
  }
  
  .datasets{
    width:90%;
  }

  .subtext
  {
    font-size: 5vw;
  }

  .landing-page-text-small
  {
    font-size: 8vw !important;
  }

  .subtext2
  {
    font-size: 4vw !important;
  }

  .search
  {
    width: 95% !important;
  }
}

@media (max-width: 900px) {
  .demo-img{
    max-width: 80% !important;
    max-height: 400px !important;
  }
}

@media (max-width: 320px) {
  .nav-bar-right{
    position: relative !important;
    right: auto !important;
  }
}

.sign-up-button{
  font-weight: bold;
}

.prod-img {
  display: block;
  max-width: 150px;
  max-height: 150px;
  width: auto;
  height: auto;
  margin-right: 20px;
  border-radius: 10%;
  object-fit: contain; /* Ensures the image is scaled properly within its container */
}

.img-with-item
{
  display: flex;
  align-items: 'center';
}
.source-button{
  margin-left: 10px;
}

.flex-row {
  display: flex;
  align-items: center;
}

.demo-img {
  margin-top: 2%;
  display: block;
  max-width: 800px;
  max-height: 800px;
  width: auto;
  height: auto;
  border-radius: 6%;
}

.landing-page-text-small{
  justify-content: center; 
  margin: auto;
  text-align: center;

  font-weight: bold;
  font-size:55px;
  max-width: 15ch;
  padding-top: 4%;
  line-height: 1.5ch;
}

.subtext2{
  font-size:20px;
  font-weight: 500;
  max-width: 25ch;
  padding-top: 1%;
  line-height: 2ch;
  padding-bottom: 5%;
}

.limit-text{
  margin-top: 2%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.search-icon{
  position: relative; /* Position the image absolutely within the search bar */
  left: 8mm; /* Adjust as needed */
  z-index: 1; 
}

.sources-div{
  margin-top:10px;
}

.bottom-margin{
  margin-bottom: 30px;
}

.move-right{
  justify-content: flex-end;
}